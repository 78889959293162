import './utils/history-polyfill';
import { initSensor } from '@utils/sensor';
import {
  loadMicroApp,
  registerMicroApps,
  addErrorHandler,
  addGlobalUncaughtErrorHandler,
  start,
} from 'qiankun';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES, PRODUCT_URL_PREFIX } from '@constants/products';
import { MicroApp } from 'qiankun/es/interfaces';
import { trackCustomEvent } from '@convivainc/conviva-js-appanalytics';
import {
  createStore,
  addGlobalStateChangeCallback,
  removeGlobalStateChangeCallback,
  GlobalState,
  OnGlobalStateChange,
} from './store';
import './resources/styles/main.less';
import './resources/icons/favicon.ico';

/* eslint-disable no-console */

const APP_DOMAIN: {
  [key in PRODUCTS]: string;
} = JSON.parse(process.env.APP_DOMAIN || '{}');

const loadEmpWidget = (
  container: string | HTMLElement,
  widgetName: string,
  props: any,
): MicroApp => {
  return loadMicroApp({
    name: PRODUCT_DISPLAY_NAMES[PRODUCTS.ACTIVATION],
    entry: APP_DOMAIN[PRODUCTS.ACTIVATION],
    container,
    props: {
      isWidget: true,
      widgetName,
      widgetProps: props,
    },
  });
};
const redirectFuction = () => {
  const { pathname, origin } = window.location;
  const integrationDataModelingReg = /\/app\/integration\/data-modeling($|([/?].*))/;
  const integrationMetricBuilderReg = /\/app\/integration\/metric-builder($|([/?].*))/;
  const integrationManagementReg = /\/app\/integration\/management($|([/?].*))/;

  if (integrationDataModelingReg.test(pathname)) {
    window.location.href = `${origin}/app/activation/home`;
  } else if (integrationMetricBuilderReg.test(pathname)) {
    window.location.href = `${origin}/app/activation/metric-builder`;
  } else if (integrationManagementReg.test(pathname)) {
    window.location.href = `${origin}/app/activation/management`;
  }
};
redirectFuction();

const microAppPerformanceTrackMap = {};

/**
 * Async register all subapps.
 *
 * ~~ Since VI is divided into Demo env and non-Demo env, ~~
 * ~~ it's necessary to determine which one is currently loaded according to the User C3Accout ID. ~~
 *
 * * SI Demo has been offlined, but also require other variables from global store. *
 *
 * We store it on global store.
 */
function register(state: GlobalState) {
  registerMicroApps(
    [
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.EI],
        entry: APP_DOMAIN[PRODUCTS.EI],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          return [
            PRODUCT_URL_PREFIX[PRODUCTS.EI],
            PRODUCT_URL_PREFIX[PRODUCTS.admin],
            PRODUCT_URL_PREFIX[PRODUCTS.profile],
            '/app/advertising-insights',
            '/app/precision/precision_policy',
            '/app/precision/resiliency',
            '/app/precision/mbr_policy',
          ].some(p => pathname.startsWith(p));
        },
      },
      {
        name: PRODUCTS.execReport,
        entry: APP_DOMAIN[PRODUCTS.execReport],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.execReport]),
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.AI],
        entry: APP_DOMAIN[PRODUCTS.AI],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.AI]),
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.VDASH],
        entry: APP_DOMAIN[PRODUCTS.VDASH],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.VDASH]),
        props: {
          basename: PRODUCT_URL_PREFIX[PRODUCTS.VDASH],
          // pass feature flags to vDash
          features: state.features,
          // c3 account list
          profiles: state.profiles,
        },
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.metadataManager],
        entry: APP_DOMAIN[PRODUCTS.metadataManager],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) =>
          pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.metadataManager]) &&
          !pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.mdsPortal]),
        props: {
          user: state.user,
          nav: state.nav,
        },
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.mdsPortal],
        entry: APP_DOMAIN[PRODUCTS.mdsPortal],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.mdsPortal]),
        props: {
          user: state.user,
          nav: state.nav,
        },
      },
      // Conviva Connect (DaaS)
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.daas],
        entry: APP_DOMAIN[PRODUCTS.daas],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.daas]),
        props: {
          user: state.user,
          nav: state.nav,
        },
      },

      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.appManager],
        entry: APP_DOMAIN[PRODUCTS.appManager],
        container: '#conviva-app-loader',

        activeRule: ({ pathname }) => {
          return pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.appManager]);
        },
      },

      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.ADVI],
        entry: APP_DOMAIN[PRODUCTS.ADVI],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.ADVI]),
      },
      {
        name: 'pulse4Loader',
        entry: {
          scripts: [window.__CONVIVA_SUBAPP_JS_STATIC_FILES__.pulse4Loader],
          // styles: [window.__CONVIVA_SUBAPP_CSS_STATIC_FILES__.pulse4Loader],
        },
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          const paths = ['/app/pulse', '/app/app_switch', '/app/precision/policies/tracking'];
          return paths.some(p => pathname.startsWith(p));
        },
      },

      // Viewer Insights
      {
        name: 'viewer-insights-dashboard',
        container: '#conviva-app-loader',
        entry: APP_DOMAIN.VI,
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX.VI),
      },
      {
        name: 'viewer-insights-social-dashboard',
        container: '#conviva-app-loader',
        entry: APP_DOMAIN.CUDA_SI,
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX.CUDA_SI),
      },
      {
        name: 'viewer-insights-social-insights',
        container: '#conviva-app-loader',
        entry: APP_DOMAIN.SI,
        activeRule: ({ pathname }) =>
          pathname.startsWith(PRODUCT_URL_PREFIX.SI) &&
          [PRODUCT_URL_PREFIX.CUDA_SI].every(it => !pathname.startsWith(it)),
      },
      {
        name: 'viewer-insights-content-analytics',
        container: '#conviva-app-loader',
        entry: APP_DOMAIN.CI,
        activeRule: ({ pathname }) =>
          pathname.startsWith(PRODUCT_URL_PREFIX.CI) &&
          [PRODUCT_URL_PREFIX.VI, PRODUCT_URL_PREFIX.SI].every(it => !pathname.startsWith(it)),
      },
      // APP Insights
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.APP],
        entry: APP_DOMAIN[PRODUCTS.APP],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.APP]),
        props: {
          loadEmpWidget,
        },
      },
      // ODP
      {
        // workaround for odp since the same name cannot be used for two products
        name: `${PRODUCT_DISPLAY_NAMES[PRODUCTS.dataManagement]}::data`,
        entry: APP_DOMAIN[PRODUCTS.dataManagement],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          return pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.dataManagement]);
        },
      },
      {
        name: `${PRODUCT_DISPLAY_NAMES[PRODUCTS.metricBuilder]}::metric`,
        entry: APP_DOMAIN[PRODUCTS.metricBuilder],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          return pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.metricBuilder]);
        },
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.ODP],
        entry: APP_DOMAIN[PRODUCTS.ODP],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          return pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.ODP]);
        },
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.ECO_SERVER],
        entry: APP_DOMAIN[PRODUCTS.ECO_SERVER],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          return pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.ECO_SERVER]);
        },
      },
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.ACTIVATION_PREVIEW],
        entry: APP_DOMAIN[PRODUCTS.ACTIVATION_PREVIEW],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) => {
          return pathname.includes(PRODUCT_URL_PREFIX[PRODUCTS.ACTIVATION_PREVIEW]);
        },
        props: {
          loadEmpWidget,
        },
      },
      // Activation
      {
        name: PRODUCT_DISPLAY_NAMES[PRODUCTS.ACTIVATION],
        entry: APP_DOMAIN[PRODUCTS.ACTIVATION],
        container: '#conviva-app-loader',
        activeRule: ({ pathname }) =>
          pathname.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.ACTIVATION]) &&
          !pathname.includes(PRODUCT_URL_PREFIX[PRODUCTS.ACTIVATION_PREVIEW]),
      },
    ],
    {
      beforeLoad: [
        async app => {
          console.log('[LifeCycle] before load %c%s', 'color: green;', JSON.stringify(app));
          const { pathname, origin } = window.location;
          const adsDetailReg = /\/app\/advertising_new\/campaigns($|([/?].*))/;
          const adsAudienceReg = /\/app\/advertising_new\/audience($|([/?].*))/;

          if (adsAudienceReg.test(pathname)) {
            window.location.href = `${origin}/app/viewer-insights/overview_page`;
          } else if (adsDetailReg.test(pathname)) {
            window.location.href = `${origin}/app/viewer-insights/ads`;
          }

          microAppPerformanceTrackMap[app.name] = {
            startTime: Date.now(),
          };
        },
      ],
      beforeMount: [
        async app => {
          console.log('[LifeCycle] before mount %c%s', 'color: green;', JSON.stringify(app));
        },
      ],
      afterMount: [
        async app => {
          try {
            console.log('[LifeCycle] after mount %c%s', 'color: green;', JSON.stringify(app));
            const { startTime } = microAppPerformanceTrackMap[app.name] || {};
            if (startTime) {
              const loadTime = Date.now() - startTime;
              trackCustomEvent({
                name: 'app_mounted',
                data: {
                  app: app.name,
                  loadTime,
                },
              });
            }
          } catch (e) {
            console.log('Error in after mount', e);
          }
        },
      ],
      afterUnmount: [
        async app => {
          console.log('[LifeCycle] after unmount %c%s', 'color: green;', JSON.stringify(app));
          delete microAppPerformanceTrackMap[app.name];
        },
      ],
    },
  );
}

const handleRegister: OnGlobalStateChange = (state, prev) => {
  if (state.user !== null && prev.user === null) {
    register(state);

    start({
      singular: true,
      prefetch: [],
      excludeAssetFilter: str => {
        const set = new Set(['https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js']);
        if (set.has(str)) return true;
        return false;
      },
      // this change was done as qiankun is having issue maintaining exec order of sub app styles
      // sandbox: false,
    });

    removeGlobalStateChangeCallback(handleRegister);
  }
};

// Trigger on GlobalStateChange callback
addGlobalStateChangeCallback(handleRegister);

initSensor();

createStore();

// Catch and print all global uncaught errors.
addGlobalUncaughtErrorHandler(event => {
  console.error(event);
});

// Only catch errors which throwed during a lifecycle function or activity function of sub appliction.
// Need show Loading Error subapp at this moment to show tips.
addErrorHandler(error => {
  console.error('LOAD ERROR.', error);

  loadMicroApp({
    name: 'loadingError',
    entry: {
      scripts: [window.__CONVIVA_SUBAPP_JS_STATIC_FILES__.loadingError],
    },
    container: '#conviva-app-loader',
  });
});

loadMicroApp({
  name: 'navApp',
  entry: {
    scripts: [window.__CONVIVA_SUBAPP_JS_STATIC_FILES__.navApp],
    // styles: [window.__CONVIVA_SUBAPP_CSS_STATIC_FILES__.navApp],
  },
  container: '#nav-app-wrapper',
});
